import axios from 'axios'
// 2、创建axios的实例
const httpService = axios.create({
  // TODO:具体的配置可以根据项目情况而来
  baseURL: process.env.NODE_ENV === 'development' ? '/' : '/back',
  timeout: 5000000
})
httpService.defaults.baseURL = 'https://cdr.jiubaojiankang.cn/'

// 5、get请求的封装
export function get (url, params = {},headers = { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' }) {
  var that = this
  return new Promise((resolve, reject) => {
    if(window.localStorage.getItem("token")){
      headers.token = window.localStorage.getItem("token")
    }
    if(window.localStorage.getItem("currentTime")){
      headers.currentTime = window.localStorage.getItem("currentTime")
    }
    if(window.localStorage.getItem("uid")){
      headers.uid = window.localStorage.getItem("uid")
    }
    httpService({
      url: url,
      method: 'get',
      params: params,
      headers: headers
    }).then(res => {
      if(res.data.code ==-1){
        /**退出登录 */
        // that.$message.error('请重新登录')
        location.reload()
      }else{
        // that.$message.success('正常')
      }
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}

// 6、post请求封装  默认json格式:'Content-Type':'application/json',如果是文件上传，可以修改headers为 headers: { 'Content-Type': 'multipart/form-data' }
export function post (url, params = {}, headers = { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' }) {
  return new Promise((resolve, reject) => {
    if(window.localStorage.getItem("token")){
      headers.token = window.localStorage.getItem("token")
    }
    if(window.localStorage.getItem("currentTime")){
      headers.currentTime = window.localStorage.getItem("currentTime")
    }
    if(window.localStorage.getItem("uuid")){
      headers.uuid = window.localStorage.getItem("uuid")
    }
    httpService({
      url: url,
      method: 'post',
      data: params,
      headers: headers
    }).then(res => {
      if(res.data.code ==-1){
        /**退出登录 */
        // that.$message.error('请重新登录')
        window.sessionStorage.removeItem('power')
        window.sessionStorage.removeItem('bool')
        window.localStorage.removeItem('token')
        window.localStorage.removeItem('uid')
        window.localStorage.removeItem('menu')
        location.href='toLogin'
      }else if(res.data.code == 4){
        location.href='/superExceptions'
        // that.$message.success('正常')
      }
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}
// 7、将模块暴露
export default {
  get,
  post
}
