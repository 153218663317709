import { get,post } from '@/util/request.js'

const api ={
	getBannerList : '/pcWeb/getIndexLogo',
	getAllCdrRecommendedProducts : '/pcWeb/getAllCdrRecommendedProducts',
	getNotice: '/pcWeb/notice',
	getProductGenreList : '/pcWeb/getProductGenreList',
	selectGoodsInfo : '/pcWeb/selectGoodsInfo',


}
/*获取menu信息 */
export function getBannerList(){
	return post(
		api.getBannerList
	)
}
export function getAllCdrRecommendedProducts(){
	return get(
		api.getAllCdrRecommendedProducts
	)
}
export function getNotice(data){
	return post(
		api.getNotice,
		data
	)
}
export function getProductGenreList(data){
	return post(
		api.getProductGenreList,
		data
	)
}
export function selectGoodsInfo(data){
	return post(
		api.selectGoodsInfo,
		data
	)
}
